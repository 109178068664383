import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { AuthService, User } from '@auth0/auth0-angular';
import { Resource, ResourceTopic } from 'src/app/models/AccountManagementModels';
import { faUsers, faUsersGear, faPeopleRoof, faEarthAmericas, faEllipsis, faDownload, faComments} from '@fortawesome/free-solid-svg-icons';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn, backInDown, backOutDown } from 'ng-animate';
import {  Router } from '@angular/router';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrls: ['./resource-detail.component.css'],
  animations: [ 
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('backOutDown', [transition('* => *', useAnimation(backOutDown))]),
    trigger('backInDown', [transition('* => *', useAnimation(backInDown))]),
    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],

})
export class ResourceDetailComponent implements OnInit {
  resources: Resource[] = [];
  title: string;
  icon: any;
  bounceIn: any;
  fadeIn: any;
  backOutDown: any;
  backInDown: any;
  faUsers = faUsers;
  faUsersGear = faUsersGear;
  faPeopleRoof = faPeopleRoof;
  faEarthAmericas = faEarthAmericas;
  faEllipsis = faEllipsis;
  faDownload = faDownload;
  faComments = faComments;
  loadingResources = true;
  noResources = false;
  iconClass: string;
  activeResource: any;
  networkResourceTopics: ResourceTopic[] = [];


  icons = {
    1: faUsers,
    2: faUsersGear,
    3: faPeopleRoof,
    4: faEarthAmericas,
    5: faEllipsis
  };

  titles = {
    1: 'Recruitment',
    2: 'Staff Management',
    3: 'Housing & Transportation',
    4: 'Immigration',
    5: 'Miscellaneous'
  };

  iconClasses = {
    1: 'recruitment',
    2: 'staff-management',
    3: 'housing-transportation',
    4: 'immigration',
    5: 'miscellaneous'
  };

  rotations = {
    1: '',
    2: 'rotate-90',
    3: 'rotate-45',
    4: 'rotate-130',
    5: 'rotate-245'
  };


  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    public auth: AuthService,
    private dialog: MatDialog,
    private router: Router

  ) {}

  ngOnInit(): void {
    this.loadingResources = true;

    this.route.queryParamMap.subscribe(params => {
      const topicId = +params.get('topic');
      this.title = this.titles[topicId];
      this.icon = this.icons[topicId];
      this.iconClass = `${this.iconClasses[topicId]} ${this.rotations[topicId]}`;
      this.resources = null;
      this.apiService.getNetworkResourceTopics().subscribe((topics: ResourceTopic[]) => {
          this.networkResourceTopics = topics;
          console.log(this.networkResourceTopics);
          }
      );


      this.apiService.getResourcesByTopicId(topicId).subscribe((resources: Resource[]) => {
        this.resources = resources;
        
        if(!this.resources.length) {
          this.noResources = true;
        }
        else
        {
          this.noResources = false;
        }
        this.loadingResources = false;
      });
    });
  }

  navigateIndex(topic: string): void {
    this.router.navigate(['/resources']);
  }
  
  navigate(topic: string): void {
    this.router.navigate(['/resources/resource-detail'], { queryParams: { topic } });
  }

  getResourcesByCategory(categoryId: number): Resource[] {
    return this.resources.filter(resource => resource.categoryId === categoryId);
  }

  openWebinar(resource: Resource): void {
    this.openInfoModal(resource.description, resource.link, resource.title);

  }


  openInfoModal(message: string, url?: string, title?: string): void {
    const dataObject = { message: message };

    if (url) {
      dataObject['url'] = url;
    }
    if(title) {
      dataObject['title'] = title;
    }

    const dialogRef = this.dialog.open(InfoModalComponent, {
      data: dataObject,
      maxWidth: '70vw',
      maxHeight: '80vh',
    });

    dialogRef.afterClosed().subscribe(() => {
      // Handle the modal close event if needed
    });
  }
}

<div class="container" *ngIf="isLoading">
    <app-loading></app-loading>
</div>

<form class="form" *ngIf="!isLoading" (ngSubmit)="onSubmit()">
    <h1 class="center-title">Find Transportation for Your Workers</h1>
     <h4 class="center-subtitle">Commute with Enterprise provides employers with cost-effective commuter benefits for their seasonal staff.

</h4> 

    <mat-card>
        <div class="housing-contact-form-mat-card">
            <!-- <h1 class="center-title-join" *ngIf="housingPartner">{{housingPartner.partnerName}}
            </h1>
            <p class="center-paragraph">Commute With Enterprise</p> -->

            <mat-card-content>
                <!-- Wrapper for transportation image -->
                <div fxLayout="row"  fxLayoutGap.gt-xs="20px" >
                    <!-- Transportation Image -->
                    <div fxFlex="30" fxFlex.lt-sm="100" fxFlex.lt-md="100" fxFlex.lt-xl="100" fxFlex.lt-xs="100" fxLayout="column" >
                        <img fxLayoutAlign="center center" class="responsive-image" src="../../../assets/CommuteShoot_20.jpg" alt="Transportation">
                    </div>
                    
            
                    <!-- Logo and Description -->
                    <div fxFlex="70" fxFlex.lt-sm="100" fxFlex.lt-md="100" fxFlex.lt-xl="100" fxFlex.lt-xs="100" fxLayout="column" fxLayoutAlign="center center">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <img class="responsive-image" src="../../../assets/logo-dark-commute-by-enterprise.png" alt="Transportation">
                        </div>
                        <div class="description-text">
                            <ul>
                                <li>Comprehensive solutions for your workplace with full program management and local support.</li>
                                <li>Tools to map opportunity and help onboard new participants.</li>
                                <li>Recent-model SUVs, crossovers, and vans with routine maintenance included.</li>
                                <li>Vehicle liability, physical damage protection, and 24/7 roadside assistance included.</li>
                                <li>Participant dashboards show ongoing savings and environment impact, plus quarterly reports to evaluate overall program success.</li>
                            </ul>
                            Worker transportation can be complicated. Seasonal Connect is partnered with Commute with Enterprise to provide an efficient and cost-effective solution for your organization’s daily transportation needs. Click the link below to connect with Commute with Enterprise and solve your transportation challenges today.
                        </div>
                    </div>
                </div>
            </mat-card-content>

            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit" class="submit-button" color="warn"
                    [disabled]="isMessageSent">Connect with Commute with Enterprise</button>

            </mat-card-actions>
        </div>
    </mat-card>
</form>
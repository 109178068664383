import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import config from '../auth_config.json';
import { Observable, of } from 'rxjs';
import { AcceptTermsModel, CreateEmployerModel, CreateWorkerModel, Employer,Network,PlanPriceResponse } from './models/SignupModels';
import { CancelReport, Customer, Invoice, Package } from './models/ChargeOverModels';
import { EmployerContactSubscriptionDTO, ChangePlanRequest, Contact, AddHousingPartnerContactLog, AddTransportationPartnerContactLog, Resource, ResourceTopic } from './models/AccountManagementModels';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  createEmployer(createEmployerModel: CreateEmployerModel): Observable<Employer>  {
    return this.http.post(`${config.apiUri}/signup/SignupEmployer/`, createEmployerModel) as Observable<Employer>;
  }

  createWorker(createWorkerModel: CreateWorkerModel): any  {
    return this.http.post(`${config.apiUri}/signup/SignupWorker/`, createWorkerModel) as any;
  }
  addHousingPartnerContactLog(addHousingPartnerContactLog: AddHousingPartnerContactLog): any  {
    return this.http.post(`${config.apiUri}/api/PartnerContact/AddHousingPartnerContactLog/`, addHousingPartnerContactLog) as any;
  }

  addTransportationPartnerContactLog(addTransportationPartnerContactLog: AddTransportationPartnerContactLog): any  {
    console.log("in api");
    return this.http.post(`${config.apiUri}/api/PartnerContact/AddTransportationPartnerContactLog/`, addTransportationPartnerContactLog) as any;
  }

  getHousingPartner(housingPartnerId: number): any  {
    return this.http.get(`${config.apiUri}/api/PartnerContact/GetHousingPartner/${housingPartnerId}`) as any;
  }
  getActivePartners(): any  {
    return this.http.get(`${config.apiUri}/api/PartnerContact/GetActivePartners/`) as any;
  }
  getCompanyDetails(employerId: string, confirmationCode: string): Observable<CreateEmployerModel> {
    return this.http.get(`${config.apiUri}/signup/SignupEmployer/GetCompanyDetails/${employerId}/${confirmationCode}`) as Observable<CreateEmployerModel>;
  }

  getEmployers(): Observable<Employer[]>  {
    return this.http.get(`${config.apiUri}/signup/SignupEmployer/GetEmployers`) as Observable<Employer[]>;
  }

  checkParentZipCode() {
    return this.http.get(`${config.apiUri}/api/CheckParentZipCode`);
  }

  getPlanPrice(plan: string, yearly: boolean, couponCode: string) {
    return this.http.get(`${config.apiUri}/signup/SignupEmployer/GetPlanPrice/${plan}/${yearly}?couponCode=${couponCode}`) as Observable<PlanPriceResponse>;
  }

  checkConfirmationCode(code: string, employerId: string) {
    return this.http.get(`${config.apiUri}/signup/SignupEmployer/VerifyConfirmationCode/${employerId}/${code}`);    
  }

  sendNewConfirmationCode(employerId: number) {
    return this.http.post(`${config.apiUri}/signup/SignupEmployer/ConfirmationCode?employerId=${employerId}`,null);   
  }
  sendConfirmationCodeEmail(email: string) {
    return this.http.post(`${config.apiUri}/signup/SignupWorker/ConfirmationCode/`,{email});   
  }

  saveDetails(details: any) {
    return true;
    // Call your API here
  }

  addPaymentMethod(paymentDetails: any) {
    return true;
    // Call your API here
  }

  //Account management
  getPrimaryEmployerForContact(contactId: number) {
    var response = this.http.get(`${config.apiUri}/api/AccountManagement/GetPrimaryEmployerForContact/${contactId}`) as Observable<EmployerContactSubscriptionDTO>;
    return response;
  }

  getChargeoverProfile(employerId: number) {
    return this.http.get(`${config.apiUri}/api/AccountManagement/GetChargeoverProfile/${employerId}`) as Observable<Customer>;
  }

  getChargeoverSubscription(employerId: number) {
    return this.http.get(`${config.apiUri}/api/AccountManagement/GetSubscriptionForEmployer/${employerId}`) as Observable<Package>;
  }

  getAllChargeoverInvoices(employerId: number) {
    return this.http.get(`${config.apiUri}/api/AccountManagement/GetAllInvoicesForEmployer/${employerId}`) as Observable<Invoice[]>;
  }

  GetConfigurationParam(appSetting: string) {
    return this.http.get(`${config.apiUri}/api/AccountManagement/GetConfigurationParam/${appSetting}`) as Observable<string>;
  }
  GetConfigurationParamForSignup(appSetting: string) {
    return this.http.get(`${config.apiUri}/signup/SignupWorker/GetConfigurationParam/${appSetting}`) as Observable<string>;
  }

  CancelChargeoverSubscription(employerId: number, contactId: number) {
    return this.http.post(`${config.apiUri}/api/AccountManagement/CancelSubscriptionForEmployer/${employerId}/${contactId}`,null) as Observable<any>;
  }

  UpdateChargeoverSubscription(employerId: number, contactId: number, changePlanRequest: ChangePlanRequest) {
    return this.http.post(`${config.apiUri}/api/AccountManagement/UpgradeSubscriptionForEmployer/${employerId}/${contactId}`,changePlanRequest) as Observable<any>;
  }

  BuyChargeoverSubscription(employerId: number, contactId: number, changePlanRequest: ChangePlanRequest) {
    return this.http.post(`${config.apiUri}/api/AccountManagement/BuySubscriptionForEmployer/${employerId}/${contactId}`,changePlanRequest) as Observable<any>;
  }

  getContacts(employerId: number) {
    return this.http.get(`${config.apiUri}/api/AccountManagement/AdminOrder/${employerId}`) as Observable<Contact[]>;
  }

  updateContacts(contacts: any) {
    return this.http.post(`${config.apiUri}/api/AccountManagement/UpdateAdminOrder/`,contacts);
  }

  IsFeatureLimited(employerId: number,identifier: string): Observable<number> {
    return this.http.get(`${config.apiUri}/api/AccountManagement/IsFeatureLimited/${employerId}/${identifier}/`) as Observable<number>;
  }

  GetPageByName(pageName: string): Observable<any> {
    const encodedPageName = encodeURIComponent(pageName);
    return this.http.get(`${config.apiUri}/signup/SignupEmployer/GetPage?name=${encodedPageName}`) as Observable<any>;
  }

  GetStateName(stateProvId: number) {
    return this.http.get(`${config.apiUri}/api/AccountManagement/GetStateName/${stateProvId}`) as Observable<any>;
  }
  
  acceptTerms(acceptTerms: AcceptTermsModel): Observable<Employer>  {
    return this.http.post(`${config.apiUri}/api/AccountManagement/AcceptTerms/`, acceptTerms) as Observable<Employer>;
  }
  
  GetCancelReport(reportId: number, employerId: number): Observable<CancelReport> {
    return this.http.get(`${config.apiUri}/api/AccountManagement/GetCancelReport/${reportId}/${employerId}/`) as Observable<CancelReport>;
  }
  GetDowngradePendingReport(employerId: number): Observable<CancelReport> {
    return this.http.get(`${config.apiUri}/api/AccountManagement/GetDowngradePendingReport/${employerId}/`) as Observable<CancelReport>;
  }

  getNetwork(networkId: number): Observable<Network>  {
    return this.http.get(`${config.apiUri}/signup/SignupEmployer/GetNetwork/${networkId}/`) as Observable<Network>;
  }

  //resources
  getResourcesByTopicId(topicId: number): Observable<Resource[]> {
    return this.http.get<Resource[]>(`${config.apiUri}/api/Resources/${topicId}`);
  }

  getNetworkResourceTopics(): Observable<ResourceTopic[]> {
    return this.http.get<ResourceTopic[]>(`${config.apiUri}/api/Resources/network-topics`);
  }
  
}
